import './scripts/common';
import {find} from '@elements/dom-utils';
import * as affixShare from './scripts/affix-share';
import {createAffix} from '@elements/affix';
import * as inlineNav from './scripts/inline-nav';
import * as selectLink from './scripts/select-link';
import * as leafletMap from './scripts/leaflet';
import * as filterViewToggle from './scripts/filterview-toggle';
//events
import * as expandSearch from './scripts/expand-search';
import * as eventFilter from './scripts/event-filter';
import * as clearSelection from './scripts/clear-selection';
import * as brochures from './scripts/brochures';
import * as bulletinSwitch from './scripts/bulletin-switch';
import * as webcamShare from './scripts/webcam-share';
import * as moodboardFilter from './scripts/moodboard-filter';
import * as fullScreenMap from './scripts/fullscreen-map';
import * as googleMapStatic from './scripts/google-map-static';
import * as selectPlaceholder from './scripts/select-placeholder';
import * as lightboxExtend from './scripts/lightbox-extend';
import * as loadMore from './scripts/load-more';
import * as flexviewadmin from './scripts/flexviewadmin';
import * as imgGridSlider from './scripts/img-grid-slider';
import * as headerImageSliderMobile from './scripts/header-image-slider-mobile';
import * as webcamSlider from './scripts/webcam-slider';
import * as contentSlider from './scripts/content-slider';
import * as expandable from './scripts/expandable';
import * as submitOnChange from './scripts/submit-on-change';
import * as availabilityDatepicker from '@elements/datepicker';
import * as rangeSlider from '@elements/range-slider';
import {createAjaxForm} from '@elements/ajax-form';
import * as overlayToggle from './scripts/overlay-toggle';
import * as account from './scripts/account';
import * as submitFormOnMobile from './scripts/tab-input';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

affixShare.init();

if (find('.js-inline-nav')) {
    createAffix(find('.js-inline-nav'), {mediaQuery: '(min-width: 768px)'}, {
        base: '.js-inline-nav',
        placeholder: '.js-inline-nav-placeholder',
        container: '.js-inline-nav-container'
    });
}

if (find('.js-affix-map') && matchMedia('(min-width: 768px)').matches) {
    createAffix(find('.js-affix-map'), {mediaQuery: '(min-width: 768px)'}, {
        base: '.js-affix-map',
        placeholder: '.js-affix-map__placeholder',
        container: '.js-affix-map__container'
    });
}

inlineNav.init();

selectLink.init();

leafletMap.init();

filterViewToggle.init();

expandSearch.init();

eventFilter.init();

clearSelection.init();

brochures.init();

bulletinSwitch.init();

webcamShare.init();

moodboardFilter.init();

fullScreenMap.init();

googleMapStatic.init();

selectPlaceholder.init();

// import {createLightbox} from '@elements/lightbox';
// createLightbox(find('.js-lightbox-gallery'), {plugins: ['video']},{
//     base: '.js-lightbox-gallery',
//     item: '.js-lightbox-gallery__item',
// });
lightboxExtend.init();

loadMore.init();

if (_config.flexviewadmin) {
    flexviewadmin.init();
}

imgGridSlider.init();

headerImageSliderMobile.init();

webcamSlider.init();

contentSlider.init();

expandable.init();

submitOnChange.init();

if (find('.js-availability-datepicker')){
    availabilityDatepicker.createDatepicker(find('.js-availability-datepicker'),
        {
            showMonths: 1
        },
        {
            base: '.js-availability-datepicker',
            input: '.js-availability-datepicker__input',
            altField: '.js-availability-datepicker__alt-field'
        });

}


rangeSlider.init();


let ajaxFormSubmitOnChange = false;
if(matchMedia('(min-width: 768px)').matches) {
    ajaxFormSubmitOnChange = true;
}
if(find('.js-ajax-form-overlay')){
    createAjaxForm(find('.js-ajax-form-overlay'), {
        submitOnChange: ajaxFormSubmitOnChange,
        allowScriptTags:true,
        addUrlParams: true,
    }, {
        result: '.js-ajax-form-overlay__result',
        loading: '.js-ajax-form-overlay__loading',
        notifications: '.js-ajax-form-overlay__notifications',
        form: '.js-ajax-form-overlay__form',
        additionalForm: '.js-ajax-form-overlay__additional-form',
        errorArea: '.js-ajax-form-overlay__error-area',
        retry: '.js-ajax-form-overlay__retry',
        link: '.js-ajax-form-overlay__link',
        reset: '.js-ajax-form-overlay__reset',
    });
}

overlayToggle.init();

account.init();


submitFormOnMobile.init();



    document.querySelectorAll('.js-login-cookie').forEach(function (element) {
        console.log(element)
        element.addEventListener('click', function () {
            var date = new Date()
            date.setTime(date.getTime() + (15 * 60 * 1000))
            var expires = "; expires=" + date.toUTCString()
            document.cookie = "login_cookie=" + encodeURIComponent(window.location.href) + expires + "; path=/"
            console.log(document.cookie)
        })
    })
